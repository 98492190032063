import { useEffect } from "react";
import { useRouter } from "next/router";

import { UserType } from "../../types/api";

export const useSecureRoute = (user: UserType) => {
  const router = useRouter();

  useEffect(() => {
    if (!user) return;

    const { enterpriseId, organisationId, organisation, role } =
      user.userRoles?.[0] ?? {};

    const allEnterpriseIds = user.userRoles?.map(
      (userRole) => userRole.enterpriseId
    );

    const urlEnterpriseId = parseInt(router.query.enterpriseId as string, 10);
    const isEnterpriseAdmin = role?.type === "ENTERPRISE_ADMIN";
    const isOrganisationAdmin = role?.type === "ORGANISATION_ADMIN";
    const urlOrgId = parseInt(router.asPath.split("/")?.[4], 10);
    const orgEnterpriseId = organisation?.enterpriseId;

    const isEnterpriseIdInPath = allEnterpriseIds?.includes(urlEnterpriseId);

    if (isEnterpriseAdmin) {
      if (
        (router.asPath.includes("/enterpriseDashboard/") &&
          (enterpriseId === undefined || !isEnterpriseIdInPath)) ||
        (!Number.isNaN(urlEnterpriseId) &&
          !allEnterpriseIds?.includes(urlEnterpriseId))
      ) {
        router.push("/unauthorized");
      }
    } else if (isOrganisationAdmin) {
      if (
        (enterpriseId !== undefined &&
          !Number.isNaN(urlEnterpriseId) &&
          orgEnterpriseId !== urlEnterpriseId) ||
        (organisationId !== undefined &&
          !Number.isNaN(urlOrgId) &&
          organisationId !== urlOrgId) ||
        /^\/enterpriseDashboard\/\d+\/$/.test(router.asPath)
      ) {
        router.push("/unauthorized");
      }
    }
  }, [user, router]);
};
