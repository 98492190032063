import React, { createContext, useContext, useMemo, useState } from "react";
import { useRouter } from "next/router";

import { useAddTaskModal } from "../hooks/useNavigateToAddTask";
import { CIItem } from "../types/api";
import { RailItem } from "../utils/mrv";

interface ModalDataContextType {
  currentStage: number;
  setCurrentStage: React.Dispatch<React.SetStateAction<number>>;
  isSingleStep: boolean;
  currentCIItem: CIItem | null;
  handleAddToPlanClick: (isSamRecommended?: boolean) => void;
  resetStage: () => void;
  setCurrentCIItem: React.Dispatch<React.SetStateAction<CIItem | null>>;
  resetCIItem: () => void;
  samRailItems: RailItem[];
  setSamRailItems: React.Dispatch<React.SetStateAction<RailItem[]>>;
  setIsSingleStep: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDataContext = createContext<ModalDataContextType>({
  currentStage: 0,
  setCurrentStage: () => {},
  isSingleStep: false,
  currentCIItem: null,
  handleAddToPlanClick: () => {},
  resetStage: () => {},
  setCurrentCIItem: () => {},
  resetCIItem: () => {},
  samRailItems: [],
  setSamRailItems: () => {},
  setIsSingleStep: () => {},
});

export const useModalDataProvider = () => useContext(ModalDataContext);

export const ModalDataProvider = ({ children }: any) => {
  const router = useRouter();
  const [currentStage, setCurrentStage] = useState(0);
  const [currentCIItem, setCurrentCIItem] = useState<CIItem | null>(null);
  const [samRailItems, setSamRailItems] = useState<RailItem[]>([]);
  const [isSingleStep, setIsSingleStep] = useState(false);
  const { navigateToAddTask } = useAddTaskModal();

  const handleAddToPlanClick = (isSamRecommended = false) => {
    if (isSamRecommended) {
      navigateToAddTask(router.query.farmId as string);
      setIsSingleStep(true);
    }
    setCurrentStage(1);
  };
  const resetStage = () => setCurrentStage(0);
  const resetCIItem = () => setCurrentCIItem(null);

  const contextValue = useMemo<ModalDataContextType>(
    () => ({
      currentStage,
      setCurrentStage,
      isSingleStep,
      currentCIItem,
      handleAddToPlanClick,
      resetStage,
      setCurrentCIItem,
      resetCIItem,
      samRailItems,
      setSamRailItems,
      setIsSingleStep,
    }),
    [currentStage, currentCIItem, samRailItems]
  );

  return (
    <ModalDataContext.Provider value={contextValue}>
      {children}
    </ModalDataContext.Provider>
  );
};
