import React, { createContext, useCallback, useMemo, useState } from "react";

import Snackbar from "@bxtech/bx-ui-lib/components/Snackbar";

type Variant = "success" | "error" | "info";

type SnackbarState = {
  open: boolean;
  message: string;
  variant: Variant;
};

export const SnackbarContext = createContext({
  openSnackbar: (message: string, variant: Variant) => {
    console.log("openSnackbar", message, variant);
  },
  closeSnackbar: () => {},
});

export const SnackbarProvider = ({ children }: any) => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    message: "",
    variant: "success",
  });

  const openSnackbar = useCallback((message: any, variant: any) => {
    setSnackbarState({ open: true, message, variant });
    setTimeout(() => {
      setSnackbarState((prevState) => ({ ...prevState, open: false }));
    }, 3000);
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  }, []);

  const contextValue = useMemo(
    () => ({
      openSnackbar,
      closeSnackbar,
    }),
    [openSnackbar, closeSnackbar]
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        message={snackbarState.message}
        setOpen={() => {
          openSnackbar(snackbarState.message, snackbarState.variant);
        }}
        open={snackbarState.open}
        variant={snackbarState.variant}
      />
    </SnackbarContext.Provider>
  );
};
