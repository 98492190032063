import { useRouter } from "next/router";

export const useAddTaskModal = () => {
  const router = useRouter();

  const navigateToAddTask = (id: string) => {
    router.push(
      {
        query: { ...router.query, addTaskModal: id },
      },
      undefined,
      { shallow: true }
    );
  };

  return {
    navigateToAddTask,
  };
};
