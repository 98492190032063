import React from "react";

import { UserType } from "@/types/api";

export type UserContextType = {
  user: UserType | null;
  setUser: (user: UserType | null) => void;
  location?: GeolocationPosition | null;
  locationPermission?: "granted" | "denied" | "prompt";
};

export const UserContext = React.createContext<UserContextType>({
  user: null,
  setUser: () => {},
  location: null,
  locationPermission: "prompt",
});
