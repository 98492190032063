/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useMemo, useState } from "react";
import { InstantSearch } from "react-instantsearch";
import { CssBaseline } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Analytics } from "@vercel/analytics/react";
import algoliasearch from "algoliasearch/lite";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";

import { ThemeProvider } from "@bxtech/bx-ui-lib";
import theme, { GlobalStyle } from "@bxtech/bx-ui-lib/styles/theme";

import "../globals.css";
import { ModalDataProvider } from "../context/ModalDataContext";
import { ConfirmationModalProvider } from "@/context/ConfirmationModalContext";
import { SnackbarProvider } from "@/context/SnackbarProvider";
import { UserContext } from "@/context/UserContext";
import { useInitializeApp } from "@/hooks/useInitializeApp";
import { useLocation } from "@/hooks/useLocation";
import { useSecureRoute } from "@/hooks/useSecureRoutes";
import { UserType } from "@/types/api";

const DynamicActiveTaskDetailsModal = dynamic(
  () => import("../components/ActiveTaskDetailsModal"),
  {
    loading: () => null, // Optional loading component
    ssr: false, // Disable server-side rendering for this component
  }
);

const DynamicActiveTaskSelectLocationModal = dynamic(
  () => import("../components/ActiveTaskSelectLocationModal"),
  {
    loading: () => null, // Optional loading component
    ssr: false, // Disable server-side rendering for this component
  }
);

const DynamicInActiveTaskDetailsModal = dynamic(
  () => import("../components/InactiveTaskDetailsModal"),
  {
    loading: () => null, // Optional loading component
    ssr: false, // Disable server-side rendering for this component
  }
);

const DynamicAddTaskModal = dynamic(
  () => import("../components/AddTaskModal"),
  {
    loading: () => null,
    ssr: false,
  }
);

const DynamicCarbonCreditsCalculatorModal = dynamic(
  () => import("../components/CarbonCreditsCalculator"),
  {
    loading: () => null,
    ssr: false,
  }
);

// Add this declaration to inform TypeScript about the 'dataLayer' property
declare global {
  interface Window {
    dataLayer: any[];
  }
}

// Create wrapper function for loading state
function Wrapper({ children }: { children: React.ReactNode }) {
  const { loading } = useInitializeApp();

  if (loading) {
    return <div />;
  }

  return <main style={{ overflow: "hidden" }}>{children}</main>;
}

// Create a client
const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const [user, setUser] = useState<UserType | null>(null);

  const { location, locationPermission } = useLocation();

  useEffect(() => {
    // Push data to the dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      // This should affect GTM but will not pass through to GA4
      window.dataLayer.push(args);

      // This should allow passthough to GA4
      window.dataLayer.push(arguments);
    }
    gtag("set", { user_id: user?.id });
  }, [user?.id]);

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID as string,
        user?.searchKey || ""
      ),
    [user]
  );

  useSecureRoute(user!);

  return (
    <>
      <Head>
        <title>BX Hub - Farmer</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${process.env.NEXT_PUBLIC_BX_CDN_URL}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${process.env.NEXT_PUBLIC_BX_CDN_URL}/favicon-16x16.png`}
        />
      </Head>
      <InstantSearch searchClient={searchClient}>
        <QueryClientProvider client={queryClient}>
          <UserContext.Provider
            value={{
              user,
              setUser,
              location,
              locationPermission,
            }}
          >
            <ThemeProvider theme={theme}>
              <StylesProvider injectFirst>
                <CssBaseline />
                <GlobalStyle />
                <SnackbarProvider>
                  <ModalDataProvider>
                    <Wrapper>
                      {user && (
                        <>
                          <DynamicAddTaskModal />
                          {/* TODO: refactor to useTaskDetailsModal hook? */}
                          <DynamicActiveTaskDetailsModal />
                          <DynamicActiveTaskSelectLocationModal />
                          <DynamicInActiveTaskDetailsModal />
                          <DynamicCarbonCreditsCalculatorModal />
                        </>
                      )}
                      <ConfirmationModalProvider>
                        <Component {...pageProps} />
                      </ConfirmationModalProvider>
                      <Script id="google-tag-manager">
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
                    `}
                      </Script>
                      <Analytics />
                    </Wrapper>
                  </ModalDataProvider>
                </SnackbarProvider>
              </StylesProvider>
            </ThemeProvider>
          </UserContext.Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </InstantSearch>
    </>
  );
}

export default MyApp;
