import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import nookies from "nookies";

import { auth } from "../../firebase/firebase";
import { idTokenChangedCallback } from "./idTokenChangedCallback";
import { UserContext } from "@/context/UserContext";

export function useInitializeApp() {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    if (typeof window !== "undefined") {
      (window as any).nookies = nookies;
    }

    const unsubscribe = auth.onIdTokenChanged((authUser) => {
      idTokenChangedCallback(authUser)
        .then((user) => {
          if (!user) {
            if (
              !router.asPath.includes("/forgottenPassword") &&
              !router.asPath.includes("/passwordReset") &&
              !router.asPath.includes("/changePassword")
            ) {
              router.push("/");
            }
            // Clear session storage on logout to remove cached sidebar items
            sessionStorage.clear();
            return;
          }

          setUser(user);
        })
        .catch(() => {
          router.push("/");
        })
        .finally(() => {
          setLoading(false);
        });
    });

    return () => unsubscribe();
  }, [setUser]);

  return { loading };
}
