import { User } from "firebase/auth";
import nookies from "nookies";

import coreAPI from "@/datasources/coreAPI";
import { fetchMyUser } from "@/lib/api";
import { UserType } from "@/types/api";

export const cookieOptions = {
  path: "/",
  // for security and preventing CSRF attacks
  sameSite: true,
};

const setCookies = (name: string, value: string, options?: any) => {
  nookies.destroy(null, name);
  nookies.set(null, name, value, options);
};

export const idTokenChangedCallback = async (
  authUser: User | null
): Promise<UserType | null> => {
  // Setting and unsetting the token in cookies
  if (!authUser || !authUser.email) {
    nookies.destroy(null, "token", cookieOptions);
    nookies.destroy(null, "email", cookieOptions);
    nookies.destroy(null, "firstName", cookieOptions);
    nookies.destroy(null, "userId", cookieOptions);

    return null;
  }

  const token = await authUser.getIdToken();
  setCookies("token", token, cookieOptions);
  coreAPI.setToken(token);

  return fetchMyUser();
};
