import { useEffect, useState } from "react";

export function useLocation() {
  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  const [locationPermission, setLocationPermission] =
    useState<"granted" | "denied" | "prompt">("denied");

  const handleLocationRequest = async () => {
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
    };

    const success = (pos: any) => {
      const crd = pos.coords;
      setLocation(pos);

      console.log(
        `✅ Your current position is: Latitude: ${crd.latitude}, Longitude: ${crd.longitude} with more or less ${crd.accuracy} meters.`
      );
    };

    const error = (err: { code: any; message: any }) => {
      console.log(`🟡 ERROR(${err.code}): ${err.message}`);
      // TODO: show error in snackbar for user
    };
    navigator.geolocation.watchPosition(success, error, options);
  };

  const checkLocationPermission = async () => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      setLocationPermission(result.state);
      handleLocationRequest();
    });
  };

  useEffect(() => {
    checkLocationPermission();
  }, [locationPermission]);

  return { location, locationPermission };
}
