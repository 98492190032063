/* eslint-disable no-param-reassign */
import path from "path";

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import getConfig from "next/config";
import { parseCookies } from "nookies";

export class CoreAPI {
  baseURL: string;

  token: string | null;

  axiosInstance: AxiosInstance;

  constructor() {
    const { publicRuntimeConfig } = getConfig();
    this.baseURL = publicRuntimeConfig.API_PROXY_URL;
    this.token = null;
    this.axiosInstance = axios.create();

    this.axiosInstance.interceptors.request.use(this.authorizationInterceptor);
  }

  // only for the server-side to set it from context
  setToken = (token: string | null) => {
    this.token = token;
  };

  getToken = (): string | null => {
    if (typeof window !== "undefined") {
      const { token } = parseCookies();
      return token;
    }

    return this.token;
  };

  authorizationInterceptor = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const newConfig = { ...config };

    const token = this.getToken();

    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
      return newConfig;
    }

    return newConfig;
  };

  getAbsoluteUrl = (url: string) => {
    const { origin, pathname } = new URL(this.baseURL);

    return new URL(path.join(pathname, url), origin).toString();
  };

  get = async (relativeUrl: string, config?: AxiosRequestConfig<any>) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    return this.axiosInstance.get(url, config);
  };

  post = async (
    relativeUrl: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    const response = this.axiosInstance.post(url, data, config);
    return response;
  };

  patch = async (
    relativeUrl: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    return this.axiosInstance.patch(url, data, config);
  };

  put = async (
    relativeUrl: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    return this.axiosInstance.put(url, data, config);
  };

  delete = async (relativeUrl: string, config?: AxiosRequestConfig<any>) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    return this.axiosInstance.delete(url, config);
  };
}

const coreAPI = new CoreAPI();

export default coreAPI;
